import React, { lazy, Component, Fragment, Suspense } from 'react';
import { Container, Button, Card, Icon } from 'semantic-ui-react';

import './lib/firebase.js';
import * as firebase from 'firebase/app';

import { emitTo, subscribeTo } from './lib/socket';
import { loader } from './figures/utils';

import Header from './global/Header';

const Index = lazy(() => import('./figures/Index'));

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      connected: false,
      ready: false,
    }

    this.handleLogin = this.handleLogin.bind(this);
  }

  async fetchAcl(uid) {
    try {
      const snapshot = await firebase.database().ref(`/acl/${uid}`).once('value');

      return snapshot.exists() && snapshot.val();
    } catch (err) {
      return false;
    }
  }

  componentWillMount() {
    const emitUserToken = async () => {
      const idToken = await firebase.auth().currentUser;

      emitTo('user', idToken);
    };

    firebase.auth().onAuthStateChanged(async fbUser => {
      let user = null;

      if (fbUser) {
        user = await this.fetchAcl(fbUser.uid);

        if (!user) {
          await firebase.auth().signOut();
        }
      }

      this.setState({
        ready: true,
        user: user ? fbUser.uid : null,
      });

      await emitUserToken();
    });

    subscribeTo('auth', async data => {
      if (data === 'unauth') {
        await emitUserToken();
      }
    });

    subscribeTo('connect', () => {
      this.setState({
        connected: true,
      });
    });

    subscribeTo('disconnect', () => {
      this.setState({
        connected: false,
      });
    });
  }

  componentWillUnmount() {
    emitTo('user', null);
  }

  handleLogin() {
    const googleProvider = new firebase.auth.GoogleAuthProvider();

    firebase.auth().signInWithRedirect(googleProvider);
  }

  render() {
    const { user, ready, connected } = this.state;

    if (!ready) {
      return loader('Loading, please wait ...');
    }

    if (!user) {
      return (
        <Fragment>
          <Header connected={connected} />
          <Container>
            <Card centered color="red">
              <Card.Content header="Login" />
              <Card.Content description="You must login to continue." />
              <Card.Content extra>
                <Button color="google plus" onClick={this.handleLogin}>
                  <Icon name="google" /> Google Login
                </Button>
              </Card.Content>
            </Card>
          </Container>
        </Fragment>
      )
    }

    return (
      // <Charts />
      <Suspense fallback={loader('Loading ...')}>
        <Index user={user} connected={connected} />
      </Suspense>
    )
  }
}

export default App;
