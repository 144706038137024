import React from 'react';
import { Loader } from 'semantic-ui-react';

export const localeNumber = (currency, price, digits) => {
  if (!price) {
    price = 0;
  }

  return (currency === 'AUD' ? '$' : '') + price.toLocaleString(undefined, {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  });
}

export const loader = (text) => {
  return <Loader active inline="centered">{ text ? text : '' }</Loader>;
}

export const getExchange = (exchange) => {
  switch (exchange) {
    case 'btcm':
      return 'BTC Markets';
    case 'cje':
      return 'CoinJar Exchange';
    default:
      return '';
  }
}
