import openSocket from 'socket.io-client';

const  socket = openSocket('https://autows.sketchthat.com');
// const  socket = openSocket('http://localhost:4001');

function subscribeTo(topic, cb) {
  socket.on(topic, cb);
}

function unsubscribeFrom(topic) {
  socket.removeAllListeners(topic);
}

function emitTo(topic, message) {
  socket.emit(topic, message);
}

export { subscribeTo, emitTo, unsubscribeFrom };
