import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';

const config = {
  apiKey: 'AIzaSyCm7PMYfjPh06HmWIKor44np8WwPczhFb4',
  authDomain: 'crypto-68fab.firebaseapp.com',
  databaseURL: 'https://crypto-68fab.firebaseio.com',
  projectId: 'crypto-68fab',
  storageBucket: 'crypto-68fab.appspot.com',
  messagingSenderId: '748491075821'
};

export default firebase.initializeApp(config);
